import Phaser from 'phaser';

export default class Preloader extends Phaser.Scene
{

	constructor() {
		super('preloader')
	}

	preload() {

	    // INTRO SCREENS
	    this.load.image('title', 					'assets/intro/title-screen.png')
	    this.load.image('click-to-play-coin', 		'assets/intro/click-to-play-coin.svg')

	    // LEVELS: These are output from Tiled and essentially form an array of where each tile should go for the different levels
	    this.load.tilemapTiledJSON('level1', 		'assets/levels/level1.json');
	    this.load.tilemapTiledJSON('level2', 		'assets/levels/level2.json');
	    this.load.tilemapTiledJSON('level3', 		'assets/levels/level3.json');

	    // TILES: This is the graphic(s) that is split up into squares and output according to the array stored in the level json files
	    this.load.spritesheet('SoftWorldPack', 		'assets/tiles/SoftWorldPack.png', {frameWidth: 70, frameHeight: 70});
	    
	    // ITEMS: These are grabbed or thrown by the player
	    this.load.image('coin', 					'assets/items/coinGold.png');
	    this.load.image('fireball', 				'assets/items/fireball.png')
	    this.load.multiatlas('bomb', 				'assets/items/bomb.json', 'assets/items');
	    this.load.image('mine', 					'assets/items/mine.png')
	    this.load.image('sword', 					'assets/items/sword.png')

	    // PLAYER INDICATORS: This show above the players heads when they have that power
	    this.load.image('bombpower', 				'assets/indicators/bombpower.png')
	    this.load.image('firepower', 				'assets/indicators/firepower.png')
	    this.load.image('swordpower', 				'assets/indicators/swordpower.png')
	    this.load.image('minepower', 				'assets/indicators/minepower.png')

	    // UI
		this.load.image('ui-heart-empty', 			'assets/ui/heart-empty.svg')
		this.load.image('ui-heart-full', 			'assets/ui/heart-full.svg')

	    // PLAYER CHARACTERS
	    this.load.multiatlas('puny', 				'assets/characters/puny.json', 				'assets/characters');
	    this.load.multiatlas('puff', 				'assets/characters/puff.json', 				'assets/characters');
	    this.load.multiatlas('pow', 				'assets/characters/pow.json', 				'assets/characters');
	    this.load.multiatlas('pepper', 				'assets/characters/pepper.json', 			'assets/characters');
	    this.load.multiatlas('ping', 				'assets/characters/ping.json', 				'assets/characters');

	    // HALL OF FAME
	    this.load.image('hall-of-fame-entry', 		'assets/hall-of-fame/hall-of-fame-entry.png');
	    this.load.image('background-no-text', 		'assets/hall-of-fame/background-no-text.png');
	    
	    // SELECT NUMBER OF PLAYERS
	    this.load.image('how-many-players-1', 		'assets/how-many-players/how-many-players-1.png')
	    this.load.image('how-many-players-2', 		'assets/how-many-players/how-many-players-2.png')
	    this.load.image('how-many-players-3', 		'assets/how-many-players/how-many-players-3.png')
	    this.load.image('select-number-of-players', 'assets/how-many-players/number-of-players-background.png')
	    this.load.image('select-count-indicator', 	'assets/how-many-players/select-count-indicator.png')

	    // SELECT PLAYER CHARACTER
	    this.load.image('select-character-1', 		'assets/select-character/select-character-1.png')
	    this.load.image('select-character-2', 		'assets/select-character/select-character-2.png')
	    this.load.image('select-character-3', 		'assets/select-character/select-character-3.png')	    
	    this.load.image('select-puppy', 			'assets/select-character/puppy.svg')
	    this.load.image('select-pewnie', 			'assets/select-character/pewnie.svg')
	    this.load.image('select-ping', 				'assets/select-character/ping.svg')
	    this.load.image('select-plucky', 			'assets/select-character/plucky.svg')
	    this.load.image('select-pong', 				'assets/select-character/pong.svg')
	    this.load.image('select-character', 		'assets/select-character/select-character.png') // background

	    // RESUME PREVIOUS GAME
	    this.load.image('resume-previous-game', 	'assets/resume/resume-previous-game.png')
	    this.load.image('resume-yes', 				'assets/resume/resume-yes.png')
	    this.load.image('resume-no', 				'assets/resume/resume-no.png')
	    this.load.image('savepoint-reached-text', 	'assets/resume/savepoint-reached-text.png')

	    // COMPLETE LEVEL
	    this.load.image('stage-cleared', 			'assets/complete/stage-cleared.svg')
	    this.load.image('you-win', 					'assets/complete/you-win.svg')
	    this.load.image('finish-star', 				'assets/complete/finish-star.png')

	    // ENEMIES
	    this.load.multiatlas('greenhorn', 			'assets/enemies/greenhorn.json', 			'assets/enemies');
	    this.load.multiatlas('narwad', 				'assets/enemies/narwad.json', 				'assets/enemies');
	    this.load.multiatlas('scratch', 			'assets/enemies/scratch.json', 				'assets/enemies');
	    this.load.multiatlas('thorn', 				'assets/enemies/thorn.json', 				'assets/enemies');

	    // SOUND EFFECTS
	    this.load.audio("oof", 						'assets/audio/sfx/oof.mp3');
	    this.load.audio("coin", 					'assets/audio/sfx/coin.wav');
	    this.load.audio("jump", 					'assets/audio/sfx/jump.wav');
	    this.load.audio("hit", 						'assets/audio/sfx/hit.wav');
	    this.load.audio("key", 						'assets/audio/sfx/key.wav');
	    this.load.audio("tada", 					'assets/audio/sfx/tada.wav');
	    this.load.audio("dead", 					'assets/audio/sfx/dead.wav');
	    this.load.audio("spawn", 					'assets/audio/sfx/spawn.wav');
	    this.load.audio("thud", 					'assets/audio/sfx/thud.mp3');
	    this.load.audio("fireball", 				'assets/audio/sfx/fireball.mp3');
	    this.load.audio("drop", 					'assets/audio/sfx/drop.wav');

	    // MUSIC
	    this.load.audio("sunset", 					'assets/audio/music/sunset.mp3');
	    this.load.audio("palm-beach", 				'assets/audio/music/palm-beach.mp3');
	    this.load.audio("tunnels", 					'assets/audio/music/tunnels.mp3');
	    this.load.audio("cyberfactory", 			'assets/audio/music/cyberfactory.mp3');

	}

	create() {

		// Switch to fullscreen when the "F" key is clicked
		this.input.keyboard.addKey('F').on('down', (event) => this.scale.startFullscreen())

		// Run the scene called "play" (can start on other scenes but may encounter errors if that scene is expecting data to be passed along from a previous scene)
		this.scene.run('play')
		//this.scene.run('select-characters')
		//this.scene.run('select-number-of-players')
		//this.scene.run('title')
		//this.scene.run('hall-of-fame')
		//this.scene.run('enter-name')
		//this.scene.run('resume')

	}

}