import Phaser from 'phaser'

import { drawColliders } from '../utils/debug'

import { turn, isTileUnderEnemy, isTileInFrontOfEnemy } from '../utils/characterActions'
import { getPlayersCenter, configureCamera } from '../utils/camera'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

import Lizard from '../enemies/Lizard'
import StandardEnemy from '../enemies/StandardEnemy'

import Bubble from '../characters/Bubble'
import Player from '../characters/Player'

import { sceneEvents } from '../events/EventsCenter'

export default class Game extends Phaser.Scene
{


    constructor()
    {
        super('game')
    }

    init(data) {

        this.finished = false;
        this.level = data.level;
        this.playersToLoad = data.players;
        this.worldX = data.worldX; // if present, start players on this X
        this.worldY = data.worldY; // if present, start players on this Y
    }

    preload() {

        // set new scale
        this.scale.resize(1200, 700);

        // defaults
        this.score = 0;
        this.cursors = this.input.keyboard.createCursorKeys();
        this.keys = this.input.keyboard.addKeys("W,A,S,D,U,H,J,K,SHIFT,SPACE,E,L,FORWARD_SLASH");

        this.level = this.level ? this.level : "level1";

        this.map = this.make.tilemap({key: this.level})

        this.collectedItems = [];
        this.itemsUI = [];

        this.createBackgroundLayers();

        this.createGroundLayer();

        this.createItemLayers();

        this.createActionsLayer();

        this.createEnemyLayers();

        this.players = [];
        //this.players.push(this.player1 = this.add.player(300, 4200, 'doesNothingYetButCanHandleDifferentTextures', null, 'wasd', 'ping', this))
        //this.players.push(this.player2 = this.add.player(500, 4200, 'doesNothingYetButCanHandleDifferentTextures', null, 'arrows', 'pow', this))

        let playerStartingX = (this?.worldX) ? (this.worldX - 140) : 0; // if starting position is saved, use it
        let playerStartingY = (this?.worldY) ? (this.worldY - 300) : 4200; // if starting position is saved, use it
        this.playersToLoad.forEach((player, index, arr) => {
            playerStartingX += 140
            this.players.push(this.add.player(playerStartingX, playerStartingY, 'doesNothingYetButCanHandleDifferentTextures', null, player.controls, player.character, this))
        })

        this.sounds = {
            jump: this.sound.add("jump", { loop: false }),
            fireball: this.sound.add("fireball", { loop: false }),
            drop: this.sound.add("drop", { loop: false }),
            hurt: this.sound.add("oof", { loop: false }),
            dead: this.sound.add("dead", { loop: false }),
            coin: this.sound.add("coin", { loop: false }),
            hit: this.sound.add("hit", { loop: false }),
            key: this.sound.add("key", { loop: false }),
            tada: this.sound.add("tada", { loop: false }),
            spawn: this.sound.add("spawn", { loop: false }),
            thud: this.sound.add("thud", { loop: false }),
            music: {
                sunset: this.sound.add("sunset", { loop: true }),
                palmBeach: this.sound.add("palm-beach", { loop: true }),
                tunnels: this.sound.add("tunnels", { loop: true }),
                cyberfactory: this.sound.add("cyberfactory", { loop: true }),
            }
        }
        

        this.anims.create({
            key: 'bomb-flame',
            frames: [
                {key: 'bomb', frame: 'bomb-1.png'}, 
                {key: 'bomb', frame: 'bomb-2.png'},
                {key: 'bomb', frame: 'bomb-3.png'},
            ],
            frameRate: 7,
            repeat: -1
        });

        this.anims.create({
            key: 'bomb-explode',
            frames: [
                {key: 'bomb', frame: 'explosion-1.png'}, 
                {key: 'bomb', frame: 'explosion-2.png'},
                {key: 'bomb', frame: 'explosion-3.png'},
                {key: 'bomb', frame: 'explosion-4.png'},
                {key: 'bomb', frame: 'explosion-5.png'},
                {key: 'bomb', frame: 'explosion-6.png'},
            ],
            frameRate: 14,
            repeat: 0
        });


    }

    createEnemyLayers() {
        this.enemies1Layer = this.map.getObjectLayer('enemies-1')
        this.enemies2Layer = this.map.getObjectLayer('enemies-2')
    }

    createBackgroundLayers() {
        this.map.createDynamicLayer('Background-Sky', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
        this.map.createDynamicLayer('Background-Back', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
        this.map.createDynamicLayer('Background-Mid', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
        this.map.createDynamicLayer('Background-Front', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
    }

    createForegroundLayers() {
        this.map.createDynamicLayer('Foreground-Back', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
        this.map.createDynamicLayer('Foreground-Mid', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
        this.map.createDynamicLayer('Foreground-Front', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
    }

    createItemLayers() {
        this.coinLayer = this.map.createDynamicLayer('Coins', this.map.addTilesetImage('SoftWorldPack'), 0, 0);
                
        /*
        // IF EVER DESIRING TO PUT CUSTOM OBJECTS SOMEWHERE WITHOUT NEEDING ON SPECIFIC TILE, USE THIS CODE AS A BASE
                this.itemsLayer = this.map.getObjectLayer('items') // requires an object layer titled 'items'

                this.items = this.physics.add.group({
                    classType: Phaser.Physics.Arcade.Image,
                    createCallback: (gameObject) => {
                        gameObject.body.setAllowGravity(false)
                    }
                })


                this.itemsLayer.objects.forEach(item => {

                    let image = this.items.get(item.x + (item.width * .5), item.y - (item.height * .5), 'gold-key')

                    // IN FUTURE, GET IMAGE NAME FROM PROPERTY AND THEN PASS THAT LONG
                    if (item.properties) {
                        image.properties = item.properties
                        console.log(image)
                    }

                })
        */

    }

    createActionsLayer() {

        this.actionsLayer = this.map.createFromObjects('actions')

        this.physics.world.enable(this.actionsLayer, Phaser.Physics.Arcade.STATIC_BODY)

        this.actionsLayer.forEach(overlapObj => {
            overlapObj.body.y += overlapObj.body.height
            overlapObj.y += overlapObj.body.height
            overlapObj.setVisible(false)
        })

    }

    createGroundLayer() {
        this.groundLayer = this.map.createDynamicLayer('World', [this.map.addTilesetImage('SoftWorldPack')], 0, 0);
        this.groundLayer.setCollisionByExclusion([-1]); // collisions
    }

    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    }

    respawnInactivePlayers() {
        this.players.forEach((player, index, array) => {
            if (!player.body.enable) {
                player.setAlpha(1)
                player.body.enable = true
                player.health = 3
                player.y = this.cameraCenter.y - 500; // spawn above player
                player.x = this.cameraCenter.x;
                player.handleHealthChange(this)
            }
        })
    }

    checkGameOver() {

        let activePlayers = 0;
        this.players.forEach((player, index, array) => {
            activePlayers += (player.body.enable) ? 1 : 0;
        })
        
        if (activePlayers == 0) { 

            // stop playing music
            this.backgroundMusic.stop();

            // grab save point data
            let savePoint = getCookie('savePoint')
            const savePointObj = JSON.parse(savePoint);
               
            // if save point detected, start game from it
            if (savePointObj) {

                this.scene.start("game", 
                    { 
                        "players": savePointObj.players,
                        "level": savePointObj.level,
                        "worldX": savePointObj.worldX,
                        "worldY": savePointObj.worldY
                    }
                );

            // otherwise just reset scene
            } else {

                this.scene.restart()

            }


        }

    }

    getMusic(level) {
        switch (level) {

            case "level1":
                return this.sounds.music.palmBeach;
                break;

            case "level2":
                return this.sounds.music.tunnels;
                break;

            case "level3":
                return this.sounds.music.cyberfactory;
                break;

            default:
                return this.sounds.music.sunset;
                break;

        }
    }

    create() {

        // set the boundaries of our game world
        this.physics.world.bounds.width = this.groundLayer.width;
        this.physics.world.bounds.height = this.groundLayer.height;

        this.backgroundMusic = this.getMusic(this.level)
        this.backgroundMusic.play();

        this.cameraCenter = this.add.circle(400, 700, 10, 0x9966ff);
        this.cameraCenter.visible = false;

        
        // player will collide with the level tiles 
        this.players.forEach((player, index, array) => {
            this.physics.add.collider(this.groundLayer, player);
        })

        // players collide with other players
        this.players.forEach((player, index, array) => {
            this.players.forEach((player2, index, array) => {
                this.physics.add.collider(player, player2);
            })
        })

        //this.coinLayer.setTileIndexCallback(17, this.collectCoin, this);

        this.players.forEach((player, index, array) => {
            //this.physics.add.overlap(player, this.coinLayer);
            this.physics.add.overlap(player, this.coinLayer, this.collectCoin, undefined, this);
        })
        

        this.players.forEach((player, index, array) => {
            this.physics.add.overlap(player, this.actionsLayer, this.actionHandler, undefined, this);
        })


        // player walk animation
        this.anims.create({
            key: 'walk',
            //frames: this.anims.generateFrameNames('balloonman', {prefix: 'walk', suffix: '.png', start: 1, end: 2, zeroPad: 1}),
            frames: this.anims.generateFrameNames('player', {prefix: 'p1_walk', start: 1, end: 11, zeroPad: 2}),
            frameRate: 10,
            repeat: -1
        });
        // idle with only one frame, so repeat is not neaded
        this.anims.create({
            key: 'idle',
            frames: [{key: 'player', frame: 'p1_stand'}],
            //frames: [{key: 'balloonman', frame: 'idle.png'}],
            frameRate: 10,
        });



        // configure camera
        configureCamera(this)

        // add enemies
        this.addEnemies(StandardEnemy, this.enemies1Layer)
        this.addEnemies(StandardEnemy, this.enemies2Layer)

        // create foreground layer
        this.createForegroundLayers();

        // add UI
        this.addUI()

    }

    actionIsLocked(object) {

        if (object.data?.list?.locked) { 

            if (this.hasItem(object.data?.list?.locked)) {
                return false;
            } else {
                return true;
            }

        } else { 
            return false;
        }

    }

    showMessage(message) {

        if (this.message?.active && message == this.message?.text) { return }

        this.message = this.add.text((this.cameras.main.width/2), 600, '0', {
            fontSize: '20px',
            fill: '#ffffff',
            align: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }).setOrigin(0.5).setPadding(16);

        // don't have text move
        this.message.setScrollFactor(0);

        this.message.setText(message)
        this.message.active = true;

        this.time.addEvent({
            delay: 1200,
            callback: () => {
                this.message.setVisible(false)
                this.message.active = false;
            },
            callbackScope: this,
            loop: false
        });        

    }

    actionHandler(obj1, obj2) {
        
        if (this.actionIsLocked(obj2)) {

            this.showMessage(obj2.data?.list?.lockedMessage ? obj2.data?.list?.lockedMessage : 'This is locked.')

        } else {

            switch(obj2.data?.list?.action) {

                case "savePoint":

                    if (obj2.used == true) { return }

                    if (obj2.x < (this.worldX + 200) == true) { return } // if further left of original spawn point, don't activate

                    obj2.used = true;

                    let playersCenterForSavePoint = getPlayersCenter(this.players);
                    let savePointText = this.physics.add.sprite(playersCenterForSavePoint.x, playersCenterForSavePoint.y - 600, 'savepoint-reached-text');

                    this.physics.add.collider(this.groundLayer, savePointText);
                    savePointText.setVelocity(0, 500)
                    savePointText.setBounce(0.2);
                    this.sounds.thud.play();

                    let data = {
                        players: this.playersToLoad,
                        level: this.level,
                        worldX: obj1.x,
                        worldY: obj1.y
                    }

                    setCookie('savePoint',JSON.stringify(data),1000)
                    break;

                case "respawnAll":
                    this.sounds.spawn.play(); // NEED TO FIX SO ONLY PLAYS ONCE
                    this.respawnInactivePlayers();
                    break;

                case "finish":
                    this.sounds.tada.play();
                    this.sounds.tada.setVolume(1.5);
                    this.backgroundMusic.stop();


                    if (this.finished == true) { return }

                    this.finished = true;

                    let playersCenter = getPlayersCenter(this.players);

                    var particles = this.add.particles('finish-star');
                    var emitter = particles.createEmitter();
                    emitter.setPosition(playersCenter.x, playersCenter.y - 200);
                    emitter.setSpeed(400);
                    emitter.setLifespan(3000);
                    emitter.setScale(1);
                    emitter.gravityY = 300

                    let finishText = 'you-win'
                    //let finishText = 'stage-cleared'

                    let stageCleared = this.physics.add.sprite(playersCenter.x, playersCenter.y - 600, finishText);
                    this.physics.add.collider(this.groundLayer, stageCleared);
                    stageCleared.setVelocity(0, 600)
                    stageCleared.setBounce(0.2);
                    this.sounds.thud.play();

                    let level = "level1";

                    switch (this.level) {

                        case "level1":
                            level = "level2";
                            break;

                        case "level2":
                            level = "level3";
                            break;

                        case "level3":
                            this.gameComplete()
                            break;

                    }

                    let savepointData = {
                        players: this.playersToLoad,
                        level: level,
                        worldX: 300,
                        worldY: 4200
                    }

                    setCookie('savePoint',JSON.stringify(savepointData),1000)
/*
                    this.time.addEvent({
                        delay: 200,
                        callback: () => {
                            //this.physics.world.pause();
                        },
                        callbackScope: this,
                        loop: false
                    });      
*/

                    this.time.addEvent({
                        delay: 3500,
                        callback: () => {
                            this.scene.start("game", 
                                { 
                                    "level": level,
                                    "players": this.playersToLoad
                                }
                                );
                        },
                        callbackScope: this,
                        loop: false
                    });      

                    break;

            }

        }
        
    }

    gameComplete() {

        eraseCookie('savePoint') // remove all savepoints


        this.time.addEvent({
            delay: 3500,
            callback: () => {
                
                this.scene.start("hall-of-fame-entry", 
                    { 
                        "players": this.playersToLoad
                    }
                );

            },
            callbackScope: this,
            loop: false
        });    



    }

    addUI() {

        // text showing number of coins
        this.text = this.add.text(1110, 20, '0', {
            fontSize: '40px',
            fill: '#ffffff',
            align: 'right'
        });

        // don't have text move
        this.text.setScrollFactor(0);

        let y = 0;

        this.players.forEach((player, index, array) => {
            y += 50;
            player.addHeartsUI(y, this);
        })

    }

    addEnemies(enemyClass, layerToReplace) {

        // create a group of this type of enemy
        let enemies = this.physics.add.group({
            classType: enemyClass,
            createCallback: (gameObject) => {
                gameObject.body.onCollide = true // tells object to trigger the `collide` event when colliding with another object
                //gameObject.body.setSize(gameObject.width, gameObject.height * 1.2)
                gameObject.body.setOffset(0, -4)
            }
        })

        // for each object in the layer, spawn (get()) an enemy on that object
        layerToReplace.objects.forEach(enemyObj => {
            enemies.get(enemyObj.x + (enemyObj.width * .5), enemyObj.y + (enemyObj.height * .5))
        })

        // add collisions for ground
        this.physics.add.collider(this.groundLayer, enemies, this.enemyPatrol, undefined, this)

        // add collisions for player
        this.players.forEach((player, index, array) => {
            this.physics.add.collider(player, enemies, this.handlePlayerEnemyCollision, undefined, this)
        })

        // add collisions for player fireballs
        this.players.forEach((player, index, array) => {
            this.physics.add.overlap(player.fireballs, enemies, (fireball, enemy) => { 
                enemy.hurt(fireball, this);
                fireball.destroy()
            }, undefined, this);
        })

        // add overlap for player bombs
        this.players.forEach((player, index, array) => {
            this.physics.add.overlap(player.bombs, enemies, (bomb, enemy) => { 
                if (bomb.exploding) {
                    enemy.hurt(bomb, this);
                }
            }, undefined, this);
        })

        // add overlap for player mines
        this.players.forEach((player, index, array) => {
            this.physics.add.overlap(player.mines, enemies, (mine, enemy) => { 
                if (mine.armed) {
                    mine.explode()
                    enemy.hurt(mine, this);
                }
            }, undefined, this);
        })

        // add overlap for player swords
        this.players.forEach((player, index, array) => {
            this.physics.add.overlap(player.swords, enemies, (sword, enemy) => { 
                enemy.hurt(sword, this);
            }, undefined, this);
        })

    }


    enemyPatrol(enemy, ground) {

        let x = enemy.x
        let y = enemy.y + enemy.height + 20

        if (!isTileUnderEnemy(enemy, this)) { turn(enemy); }

        if (isTileInFrontOfEnemy(enemy, this)) { turn(enemy); }

    }

    handlePlayerEnemyCollision(player, enemy)
    {
        let dx = (player.x - enemy.x) * 3
        let dy = player.y - enemy.y
        let dir = new Phaser.Math.Vector2(dx, dy).normalize()
        let threshold = 5


        let enemyTop = enemy.y - enemy.height;

        //console.log('player Y', player.y)
        //console.log('enemy top', enemyTop)

        // hurt enemy
        if (player.y - threshold <= enemyTop) {
                
            dir = dir.scale(600)
            player.setVelocity(dir.x, -400)

            enemy.hurt(player, this);
            //enemy.killed(this)

        // hurt player
        } else {
            player.handleDamage(dir);
            player.handleHealthChange(this)
        }

    }

    collectItem = (tile) => {
        this.collectedItems.push(tile)
        this.refreshItems();
    }

    hasItem = (itemName) => {

        let itemFound = false;

        this.collectedItems.forEach(item => {
            //console.log(itemName + ' to ' + item.properties.item)
            if (item.properties.item == itemName) { itemFound = true; }
        })

        return itemFound;

    }

    refreshItems = () => {

        this.itemsUI.forEach(sprite => sprite.destroy())

        let startingX = 270;

        this.collectedItems.forEach(item => {
            let itemSprite = this.add.sprite(startingX, 50, 'SoftWorldPack', (item.index - 1));
            itemSprite.setScrollFactor(0);
            this.itemsUI.push(itemSprite)
            startingX += 85;
        })

    }

    // this function will be called when the player touches a coin
    collectCoin = (player, tile) => {
        
        if (tile.index < 0) { return } // if there's not actually a tile placed here, don't do anything

        switch(tile.properties.item) {

            case "coin":
                this.coinLayer.removeTileAt(tile.x, tile.y); // remove the tile/coin
                this.score++; // add 10 points to the score
                this.text.setText(this.score); // set the text to show the current score
                this.sounds.coin.play();
                break;

            case "yellow-key":
            case "green-key":
            case "purple-key":
            case "blue-key":
                this.collectItem(tile)
                this.coinLayer.removeTileAt(tile.x, tile.y); // remove the tile/coin
                this.sounds.key.play();
                break;

            case "firepower":
            case "bombpower":
            case "swordpower":
            case "minepower":
                this.coinLayer.removeTileAt(tile.x, tile.y); // remove the tile/coin
                player.gainSuperpower(tile.properties.item)
                break;

        }



    }

    centerCameraOnPlayers() {

        let playersCenter = getPlayersCenter(this.players);
        this.cameraCenter.x = playersCenter.x;
        this.cameraCenter.y = playersCenter.y;

        // set boundaries to not allow player to go off screen
        this.leftBoundary.x = this.cameras.main.scrollX;
        this.rightBoundary.x = this.cameras.main.scrollX + this.cameras.main.width;

        let cameraBottom = this.cameraCenter.y + (this.cameras.main.height/2)
        let cameraRight = this.cameraCenter.x + (this.cameras.main.width/2)
        let cameraLeft = this.cameraCenter.x - (this.cameras.main.width/2)

        this.players.forEach((player, index, array) => {

            // if below camera or fallen in gap, instant 0 hearts
            if (player.y > cameraBottom + 100 || player.y > 4500) {
                player.health = 0;
                player.handleHealthChange(this)
            }

            // if too far off center of camera to the side, instant 0 hearts
            // UNDER DEVELOPMENT
            
            if (
                (player.body.enable && player.x < cameraLeft - 300) || 
                (player.body.enable && player.x > cameraRight + 300)
                ) {
                player.health = 0;
                player.handleHealthChange(this)
            }
            

        });

    }

    update(time, delta) {

        this.players.forEach((player, index, array) => {
            player.handleInput(this)
        })

        //this.player1.handleInput(this)
        //this.player2.handleInput(this)

        this.centerCameraOnPlayers();

    }


}
