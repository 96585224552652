import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class Title extends Phaser.Scene
{

    constructor()
    {
        super('title')
    }

    preload() {

        this.sounds = {
            music: {
                sunset: this.sound.add("sunset", { loop: true }),
            }
        }

    }

    create()
    {

    	let context = this

    	// player music
    	let music = this.sounds.music.sunset
        music.play();

    	// define spacebar
    	var spacebar = this.input.keyboard.addKey(32);

    	// add background image
    	this.add.image(600, 350, 'title');

    	// when spacebar is pressed, continue to select number of players
        spacebar.on('down', function(event) {

            // if a save point is detected, give option to pick up from it
            if (getCookie('savePoint')) {
                context.scene.start("resume", { musicPlaying: music });

            // otherwise start a new game
            } else {
                context.scene.start("select-number-of-players", { musicPlaying: music });
            }

        });

    }

}