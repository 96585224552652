import Phaser from 'phaser'

export default class SelectNumberOfPlayers extends Phaser.Scene
{

    constructor()
    {
        super('select-number-of-players')
    }

    preload() {
    }

    init(data) {
        this.musicPlaying = data.musicPlaying;
    }

    create()
    {

        let context = this;

        this.add.image(600, 350, 'select-number-of-players');

        var leftKey = this.input.keyboard.addKey('A');
        var rightKey = this.input.keyboard.addKey('D');
        var submitKey = this.input.keyboard.addKey(13); // return

        var leftKeyArrow = this.input.keyboard.addKey(37);
        var rightKeyArrow = this.input.keyboard.addKey(39);

        let selection = this.add.image(300, 320, 'select-count-indicator');

        this.add.image(300, 340, 'how-many-players-1');
        this.add.image(600, 340, 'how-many-players-2');
        this.add.image(900, 340, 'how-many-players-3');

        selection.value = 1

        leftKey.on('down', (event) => context.goLeft(selection))
        leftKeyArrow.on('down', (event) => context.goLeft(selection))

        rightKey.on('down', (event) => context.goRight(selection))
        rightKeyArrow.on('down', (event) => context.goRight(selection))

        submitKey.on('down', function(event) {

            context.scene.start("select-characters", 
                { 
                    "playerCount": selection.value,
                    "musicPlaying": context.musicPlaying
                }
            );
        });

    }

    goLeft(selection) {
        selection.value--;
        let updatedValues = this.getNewSelection(selection.value)
        selection.x = updatedValues.x
        selection.value = updatedValues.value
    }

    goRight(selection) {
        selection.value++;
        let updatedValues = this.getNewSelection(selection.value)
        selection.x = updatedValues.x
        selection.value = updatedValues.value
    }

    getNewSelection(value) {

        if (value == 0) { value = 3 }
        if (value == 4) { value = 1 }

        if (value == 1) {
            return { x: 300, value: 1 }
        } else if (value == 2) {
            return { x: 600, value: 2 }
        } else if (value == 3) {
            return { x: 900, value: 3 }
        }
    }

}