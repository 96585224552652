import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class EnterName extends Phaser.Scene
{

    constructor()
    {
        super('enter-name')
    }

    preload() {

        this.sounds = {
            music: {
                sunset: this.sound.add("sunset", { loop: true }),
            }
        }


    	this.load.html('nameform', 'assets/html/form.html');


    }

    create()
    {

    	let context = this

        this.input.keyboard.removeCapture('F'); // disable full screen button so the f key can be used for typing

    	// player music
    	let music = this.sounds.music.sunset
        music.play();

    	// add background image
    	this.add.image(600, 350, 'hall-of-fame-entry');

    	var element = this.add.dom(590, 450).createFromCache('nameform');

    	var submitKey = this.input.keyboard.addKey(13); // return

        submitKey.on('down', function(event) {

            var inputName = element.getChildByName('name');

            if (!inputName) { return } // require name
            
        	context.submit(inputName.value);

            element.destroy() // remove input

            context.input.keyboard.addCapture('F'); // re-enable full screen button

    	});


    }

    submit = (name) => {

        console.log(name)

        let data = {}
        data.name = name
        data.game = "themiggies"
        data.score = 0

        fetch('https://crate.onrender.com/rank', {
            method: 'POST',
            //mode: 'cors',
            //cache: 'no-cache',
            //credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            //redirect: 'follow',
            //referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        })
          .then(response => response.json())
          .then(data => {

            this.scene.start("hall-of-fame");

        })


        

    }

}