import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class SelectCharacters extends Phaser.Scene
{

    constructor()
    {
        super('select-characters')
    }

    preload() {

        this.topOffset = 300;
        this.leftOffset = 140;
        this.maxPerRow = 5;
        this.maxSelectionCount = 5;
        this.selectionPadding = 20;
        this.selectionWidth = 200;
        this.selectionHeight = 200;
        this.selectionRadius = 100;

    }

    init(data) {
        this.playerCount = data.playerCount;
        this.musicPlaying = data.musicPlaying;
    }

    create()
    {

        let context = this;

        this.add.image(600, 350, 'select-character');

        var playerOneLeftKey = this.input.keyboard.addKey('A');
        var playerOneRightKey = this.input.keyboard.addKey('D');

        var playerTwoLeftKey = this.input.keyboard.addKey(37);
        var playerTwoRightKey = this.input.keyboard.addKey(39);

        var playerThreeLeftKey = this.input.keyboard.addKey('H');
        var playerThreeRightKey = this.input.keyboard.addKey('K');

        var submitKey = this.input.keyboard.addKey(13); // return

        this.playerOneSelection = this.addSelection(1, 1);

        if (this.playerCount > 1) { this.playerTwoSelection = this.addSelection(2, 2); }
        if (this.playerCount > 2) { this.playerThreeSelection = this.addSelection(3, 3); }

       
        this.add.image(160, 340, 'select-puppy');
        this.add.image(160 + (220 * 1), 340, 'select-pewnie');
        this.add.image(160 + (220 * 2), 340, 'select-plucky');
        this.add.image(160 + (220 * 3), 340, 'select-ping');
        this.add.image(160 + (220 * 4), 340, 'select-pong');


        playerOneLeftKey.on('down', function(event) {
            let value = context.getNextValue(context.playerOneSelection, 'left')
            context.updateSelectionPosition(value, context.playerOneSelection)
        });

        playerOneRightKey.on('down', function(event) {
            let value = context.getNextValue(context.playerOneSelection, 'right')
            context.updateSelectionPosition(value, context.playerOneSelection)
        });

        playerTwoLeftKey.on('down', function(event) {
            let value = context.getNextValue(context.playerTwoSelection, 'left')
            context.updateSelectionPosition(value, context.playerTwoSelection)
        });

        playerTwoRightKey.on('down', function(event) {
            let value = context.getNextValue(context.playerTwoSelection, 'right')
            context.updateSelectionPosition(value, context.playerTwoSelection)
        });

        playerThreeLeftKey.on('down', function(event) {
            let value = context.getNextValue(context.playerThreeSelection, 'left')
            context.updateSelectionPosition(value, context.playerThreeSelection)
        });

        playerThreeRightKey.on('down', function(event) {
            let value = context.getNextValue(context.playerThreeSelection, 'right')
            context.updateSelectionPosition(value, context.playerThreeSelection)
        });

        submitKey.on('down', function(event) {

            eraseCookie('savePoint') // erase previous savepoint here in case user accidentally clicks into starting a new game but doesn't actually start it

            let players = [];

            if (context?.playerOneSelection?.value) { players.push({ character: context.getCharacterByValue(context.playerOneSelection.value), controls: 'wasd' }) }
            if (context?.playerTwoSelection?.value) { players.push({ character: context.getCharacterByValue(context.playerTwoSelection.value), controls: 'arrows' }) }
            if (context?.playerThreeSelection?.value) { players.push({ character: context.getCharacterByValue(context.playerThreeSelection.value), controls: 'uhjk' }) }

            context.musicPlaying.stop()

            context.scene.start("game", 
                { "players": players }
            );
        });

    }

    getCharacterByValue(value) {

        switch(value) {
            case 1:
                return 'puny'
                break;
            case 2:
                return 'puff'
                break;
            case 3:
                return 'pow'
                break;
            case 4:
                return 'pepper'
                break;
            case 5:
                return 'ping'
                break;
        }

    }

    getNextValue(selection, direction) {

        let value = selection.value;

        if (direction == 'left') {
            
            value--; // drop by one
            value = (value <= 0) ? this.maxSelectionCount : value // set to max if at beginning

            while (this.valueIsOccupied(selection, value)) { // if value is taken
                value--; // drop by one again
                value = (value <= 0) ? this.maxSelectionCount : value // set to max if at beginning
            }

            return value;

        }

        if (direction == 'right') {
            
            value++; // increase by one
            value = (value > this.maxSelectionCount) ? 1 : value // set to 1 if at end

            while (this.valueIsOccupied(selection, value)) { // if value is taken
                value++; // increase by one again
                value = (value > this.maxSelectionCount) ? 1 : value // set to 1 if at end
            }

            return value;

        }
        
    }

    valueIsOccupied(currentSelection, value) {

        // build array of all used values
        let occupiedValues = []
        if (this?.playerOneSelection?.value) { occupiedValues.push(this.playerOneSelection.value) }
        if (this?.playerTwoSelection?.value) { occupiedValues.push(this.playerTwoSelection.value) }
        if (this?.playerThreeSelection?.value) { occupiedValues.push(this.playerThreeSelection.value) }

        // remove current selection from array as selection should be allowed to cycle back to current if no options are available
        let currentValueToAllow = currentSelection.value
        const indexOfCurrentValueToAllow = occupiedValues.indexOf(currentValueToAllow);
        if (indexOfCurrentValueToAllow > -1) { // only splice array when item is found
            occupiedValues.splice(indexOfCurrentValueToAllow, 1); // 2nd parameter means remove one item only
        }

        // if value is one of the ocupied values, return true, otherwise return false
        return occupiedValues.includes(value)

    }

    addSelection(playerNumber, startingPosition) {

/*
        let selection = graphics.strokeRoundedRect(
                            this.selectionPadding, // x start
                            this.selectionPadding, // y start
                            this.selectionWidth, // width
                            this.selectionHeight, // height
                            this.selectionRadius // radius
                        );
*/

        let selection = this.add.image(this.selectionPadding, this.selectionPadding, 'select-character-' + playerNumber);

        this.updateSelectionPosition(startingPosition, selection)

        return selection;

    }

    updateSelectionPosition(value, selection) {
        let updatedValues = this.getNewSelection(value)
        selection.x = updatedValues.x
        selection.y = updatedValues.y
        selection.value = updatedValues.value
    }

    getNewSelection(value) {

        let rowNum = Math.ceil(value / this.maxPerRow);

        let percentageAcrossRow = ((value / this.maxPerRow) - (rowNum- 1 ))

        let columnNum = Math.round(this.maxPerRow * percentageAcrossRow);

        let xPosition = ((this.selectionWidth + this.selectionPadding) * (columnNum - 1)) + this.selectionPadding + this.leftOffset
        let yPosition = ((this.selectionHeight + this.selectionPadding) * (rowNum -1)) + this.selectionPadding + this.topOffset

        return { 
            x: xPosition,
            y: yPosition,
            value: value
        }

    }

}