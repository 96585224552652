import Phaser from 'phaser'
import { randomIntFromInterval } from '../utils/math'
import { directionListener } from '../utils/characterActions'

export default class Lizard extends Phaser.Physics.Arcade.Sprite
{
	constructor(scene, x, y, texture, frame)
	{

		super(scene, x, y, texture, frame) // set position
		this.direction = "right" // default walking direction
		this.speed = 100 // set speed

        // player walk animation
        this.anims.create({
            key: 'walk',
            frames: this.anims.generateFrameNames('player', {prefix: 'p1_walk', start: 1, end: 11, zeroPad: 2}),
            frameRate: 20,
            repeat: -1
        });

		this.anims.play('walk') // apply animated sprite

	}

	preUpdate = (t, dt) => { 

		super.preUpdate(t, dt)

		directionListener(this, this.speed)
	}

}