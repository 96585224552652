import Phaser from 'phaser'

import { randomIntFromInterval } from '../utils/math'

import { directionListener } from '../utils/characterActions'

export default class Player extends Phaser.Physics.Arcade.Sprite
{
	constructor(scene, x, y, texture, frame, name)
	{

		super(scene, x, y, texture, frame) // set position

        this.name = name ? name : 'puny'; // puny | puff | pow | pepper | ping

        // player walk animation
        this.anims.create({
            key: 'walk',
            //frames: this.anims.generateFrameNames('balloonman', {prefix: 'walk', suffix: '.png', start: 1, end: 2, zeroPad: 1}),
            frames: [{key: this.name, frame: 'walk.png'}, {key: this.name, frame: 'idle.png'}],
            frameRate: 7,
            repeat: -1
        });
        // idle with only one frame, so repeat is not neaded
        this.anims.create({
            key: 'idle',
            frames: [{key: this.name, frame: 'idle.png'}],
            frameRate: 10,
        });
        // jump with only one frame, so repeat is not neaded
        this.anims.create({
            key: 'jump',
            frames: [{key: this.name, frame: 'jump.png'}],
            frameRate: 10,
        });

        this.anims.play('idle')

	}

	preUpdate = (t, dt) => { 

		super.preUpdate(t, dt)

        if (this.superpower) {
            this.superpowerIndicator.x = this.x
            this.superpowerIndicator.y = this.y - 70
        }

	}

	handleInput = (context) => { 

        if (this.stunned == false) {

            // LEFT
            if (context.cursors.left.isDown && this.controls == 'arrows' || context.keys.A.isDown && this.controls == 'wasd' || context.keys.H.isDown && this.controls == 'uhjk') { this.goLeft(); }

            // RIGHT
            else if (context.cursors.right.isDown && this.controls == 'arrows' || context.keys.D.isDown && this.controls == 'wasd' || context.keys.K.isDown && this.controls == 'uhjk') { this.goRight();

            // IDLE
            } else { this.goIdle(); }

            // JUMP 
            if (context.cursors.up.isDown && this.body.onFloor() && this.controls == 'arrows' || context.keys.W.isDown && this.body.onFloor() && this.controls == 'wasd' || context.keys.U.isDown && this.body.onFloor() && this.controls == 'uhjk') { this.goJump(); }

            // WEAPON
            if (context.keys.SHIFT.isDown && this.controls == 'wasd') { this.fireWeapon(); }
            if (context.keys.SPACE.isDown && this.controls == 'uhjk') { this.fireWeapon(); }
            if (context.keys.FORWARD_SLASH.isDown && this.controls == 'arrows') { this.fireWeapon(); }

        }

	}

    fireWeapon = () => {

        switch(this.superpower) {

            case "firepower":
                this.shootFireball();
                break;

            case "bombpower":
                this.dropBomb();
                break;

            case "swordpower":
                this.swingSword();
                break;

            case "minepower":
                this.dropMine();
                break;

        }
        
    }

	addHeartsUI = (y, context) => {

        // heart health
        this.hearts = context.add.group({
            classType: Phaser.GameObjects.Image,
            createCallback: (gameObject) => {
                gameObject.setTint(this.color)
            }
        })

        this.heartsOutline = context.add.group({
            classType: Phaser.GameObjects.Image
        })

        this.hearts.createMultiple({
            key: 'ui-heart-full',
            setXY: {
                x: 50,
                y: y,
                stepX: 64
            },
            quantity: 3,
            setScale: {x: 0.3, y: 0.3},
            setScrollFactor: {x: 0, y: 0},
        })

        this.heartsOutline.createMultiple({
            key: 'ui-heart-empty',
            setXY: {
                x: 50,
                y: y,
                stepX: 64
            },
            quantity: 3,
            setScale: {x: 0.3, y: 0.3},
            setScrollFactor: {x: 0, y: 0},
        })

	}

    goLeft() {
        this.direction = "left";
        this.body.setVelocityX(-300);
        this.setAnim('walk')
        this.flipX = true; // flip the sprite to the left
    }

    goRight() {
        this.direction = "right";
        this.body.setVelocityX(300);
        this.setAnim('walk')
        this.flipX = false; // use the original sprite looking to the right
    }

    goIdle() {
        this.body.setVelocityX(0);
        this.setAnim('idle')
    }

    goJump() {
        this.body.setVelocityY(-500);
        this.context.sounds.jump.play();
    }

    swingSword() {

        if (this.waitToSwing === true) { return; }

        this.context.sounds.drop.play();

        const vec = new Phaser.Math.Vector2((this.direction == 'right') ? 1 : -1, 0)

        let velocityMultiplier = 400;
        const sword = this.swords.get(this.x, this.y, 'sword')
        //sword.flipY = (this.direction == 'right') ? false : true;
        sword.body.setSize(sword.width * 0.3, sword.height * 1)
        sword.setActive(true)
        sword.setVisible(true)
        sword.body.setDragX(800)
        sword.setRotation(vec.angle()) // rotates the object so the blade is pointed forward
        sword.setVelocity(vec.x * velocityMultiplier, vec.y * velocityMultiplier)
        sword.x += (this.direction == 'right') ? -50 : 60


        // start in correct rotation
        let startingAngleOffset = (this.direction == 'right') ? -0.34 : (0.34 * 10) // .34 radians = 20 degress
        sword.setRotation(vec.angle() + startingAngleOffset)


        if (this.direction == 'right') {
            sword.setOrigin(0, 1)
        } else {
            sword.setOrigin(1, 1)
        }

        let hitBoxRadius = 18
        let hitBoxOffsetX = (this.direction == 'right') ? 40 : -45
        let hitBoxOffsetY = (this.direction == 'right') ? 70 : 70
        sword.body.setCircle(hitBoxRadius, hitBoxOffsetX, hitBoxOffsetY)

        this.waitToSwing = true;

        this.scene.tweens.add({
            targets: sword,
            angle: (this.direction == 'right') ? 110 : -110,
            duration: 200,
            delay: 0,
            onComplete: () => {
                sword.destroy()
                this.waitToSwing = false;
            }
        });

/*
        // after x 
        this.scene.time.addEvent({
            delay: 400,
            callback: () => {
                this.waitToSwing = false;
                mine.destroy()
            },
            callbackScope: this,
            loop: false
        });
        */

    }

    dropMine() {

        if (this.waitToMine === true) { return; }

        this.context.sounds.drop.play();

        const vec = new Phaser.Math.Vector2((this.direction == 'right') ? 1 : -1, 0)

        let velocityMultiplier = 400;
        const mine = this.mines.get(this.x, this.y, 'mine')
        mine.flipY = (this.direction == 'right') ? false : true;
        mine.body.setSize(mine.width * 0.3, mine.height * 1)
        mine.setActive(true)
        mine.setVisible(true)
        mine.body.setDragX(800)
        mine.setRotation(vec.angle()) // rotates the object so the blade is pointed forward
        mine.setVelocity(vec.x * velocityMultiplier, vec.y * velocityMultiplier)

        this.waitToMine = true;

        // after x 
        this.scene.time.addEvent({
            delay: 1600,
            callback: () => {
                this.waitToMine = false;
                mine.armed = true; // armed to explode on contact
            },
            callbackScope: this,
            loop: false
        });

    }

    shootFireball() {

        if (this.waitToFire === true) { return; }

        let intervalMs = 150;

        this.context.sounds.fireball.play();

        const vec = new Phaser.Math.Vector2((this.direction == 'right') ? 1 : -1, 0)

        let velocityMultiplier = 400;
        const fireball = this.fireballs.get(this.x, this.y, 'fireball')
        fireball.setActive(true)
        fireball.setVisible(true)
        fireball.x += vec.x *12 // start the knife in front of character, not on character (but not so far ahead that it can go through walls)
        fireball.y += vec.y *12 // start the knife in front of character, not on character (but not so far ahead that it can go through walls)
        fireball.setRotation(vec.angle()) // rotates the object so the blade is pointed forward
        fireball.setVelocity(vec.x * velocityMultiplier, vec.y * velocityMultiplier)

        this.waitToFire = true;

        // after x 
        this.scene.time.addEvent({
            delay: intervalMs,
            callback: () => {
                this.waitToFire = false;
            },
            callbackScope: this,
            loop: false
        });

    }

    dropBomb() {

        if (this.waitToBomb === true) { return; }

        let fuseTimeMs = 1600;
        let intervalMs = 300

        this.context.sounds.drop.play();

        const vec = new Phaser.Math.Vector2((this.direction == 'right') ? 1 : -1, 0)

        let velocityMultiplier = 400;
        const bomb = this.bombs.get(this.x, this.y)
        bomb.flipY = (this.direction == 'right') ? false : true;
        bomb.body.setSize(bomb.width * 1.4, bomb.height * 1.7)
        bomb.body.setOffset(30, 30)
        bomb.play('bomb-flame')
        bomb.setActive(true)
        bomb.setVisible(true)
        bomb.body.setDragX(300)
        bomb.x += vec.x *12 // start the knife in front of character, not on character (but not so far ahead that it can go through walls)
        bomb.y += vec.y *12 // start the knife in front of character, not on character (but not so far ahead that it can go through walls)
        bomb.setRotation(vec.angle()) // rotates the object so the blade is pointed forward
        bomb.setVelocity(vec.x * velocityMultiplier, vec.y * velocityMultiplier)

        this.waitToBomb = true;


        this.scene.time.addEvent({ delay: intervalMs, callback: () => { this.waitToBomb = false; } });

        // after x 
        this.scene.time.addEvent({
            delay: fuseTimeMs,
            callback: () => {
                bomb.play('bomb-explode')

                bomb.body.setSize(bomb.width, bomb.height) // update bomb size to explosion size
                //bomb.body.setOffset(10, -20)
                bomb.exploding = true

                this.scene.time.addEvent({ delay: 500, callback: () => { bomb.destroy(); } });
            },
            callbackScope: this,
            loop: false
        });

    }

    setAnim(animName) {
    	if (this.body.onFloor()) { this.anims.play(animName, true); } else { this.anims.play('jump', true); } // if in air, show jump
    }

    handleHealthChange(context) {
        this.hearts.children.each((gameObject, index) => {
            const heart = gameObject;

            if (index < this.health) {
                heart.setTexture('ui-heart-full')
                heart.setTint(this.color)
            } else {
                heart.setTexture('ui-heart-empty')
                heart.setTint(this.color)
            }
        })

        if (this.health == 0) {

            context.cameras.main.flash();

            this.setAlpha(0)
            this.body.enable = false
            this.y = 0
            this.x = 0

            context.checkGameOver();
            
        }

    }

    handleDamage(dir) {

        // if currently stunned or dead or swinging weapon, don't take on more damage
        if (this.stunned || this.health == 0) {
            return
        }

        // set intensity of attack; the higher the number the further the player will fall back - later would be nice to make dependent on who attacked them and with what
        dir = dir.scale(400)
        this.setVelocity(dir.x, dir.y)
        this.stunned = true
        
        this.setTint(0x777777)

        // after x 
        this.scene.time.addEvent({
            delay: 300,
            callback: () => {
                //player.setTint(player.color)
                this.setTint(0xFFFFFF)
                this.stunned = false
            },
            callbackScope: this,
            loop: false
        });

        this.health--;

        if (this.health > 0) {
            this.context.sounds.hurt.play();
        } else {
            this.context.sounds.dead.play();
        }

    }

    gainSuperpower(superpower) {

        this.context.sounds.drop.play();
        this.superpower = superpower;
        this.superpowerIndicator.setTexture(superpower)

    }

}

function getSpriteCollisionSize(sprite) {

    let heightOffset = sprite.name == 'ping' ? 2 : 8; // ping needs 2

    return {
        width: sprite.width * 0.6,
        height:(sprite.height - heightOffset)
    }

}

Phaser.GameObjects.GameObjectFactory.register('player', function(x, y, texture, frame, controls, name, context) {

	var sprite = new Player(this.scene, x, y, texture, frame, name)
	
    sprite.superpowerIndicator = context.add.sprite(x,y+10);
    
    sprite.damage = 60 // damage when jumping on enemy

	this.displayList.add(sprite)
	this.updateList.add(sprite)
    sprite.context = context // access to game object
	
	this.scene.physics.world.enableBody(sprite, Phaser.Physics.Arcade.DYNAMIC_BODY)

    sprite.direction = 'right' // default direction

    sprite.fireballs = context.physics.add.group({ 
        classType: Phaser.Physics.Arcade.Sprite,
        createCallback: (gameObject) => {

            gameObject.damage = 50

            gameObject.body.setAllowGravity(false)

            this.scene.time.addEvent({
                delay: 500,
                callback: () => {


                    this.scene.time.addEvent({
                        delay: 40,                // ms
                        callback: () => {
                            gameObject.setAlpha(gameObject.alpha - 0.2)

                            if (gameObject.alpha <= 0) {
                                gameObject.destroy()
                            }

                        },
                        //args: [],
                        callbackScope: context,
                        loop: true
                    });

                },
                callbackScope: this,
                loop: false
            });

        }
    })

    sprite.bombs = context.physics.add.group({ 
        classType: Phaser.Physics.Arcade.Sprite,
        createCallback: (gameObject) => {
            gameObject.damage = 120
        }
    })

    sprite.swords = context.physics.add.group({ 
        classType: Phaser.Physics.Arcade.Sprite,
        createCallback: (gameObject) => {
            gameObject.damage = 35
        }
    })

    sprite.mines = context.physics.add.group({ 
        classType: Phaser.Physics.Arcade.Sprite,
        createCallback: (gameObject) => {

            gameObject.damage = 50

            gameObject.explode = () => {

                gameObject.play('bomb-explode')

                gameObject.body.setSize(gameObject.width, gameObject.height) // update bomb size to explosion size

                this.scene.time.addEvent({ delay: 500, callback: () => { gameObject.destroy(); } });

            }
        }
    })

    context.physics.add.collider(context.groundLayer, sprite.bombs);
    context.physics.add.collider(context.groundLayer, sprite.mines);

    context.physics.add.collider(context.groundLayer, sprite.fireballs, (fireball, world) => fireball.destroy(), undefined, this);



    sprite.stunned = false;
    sprite.health = 3;
    sprite.controls = controls ? controls : 'arrows';

    // create the player sprite    
    sprite.setBounce(0.2); // our player will bounce from items
    sprite.setCollideWorldBounds(true); // don't go out of the map    
    
    // update collision box
    let collisionSize = getSpriteCollisionSize(sprite);
    sprite.body.setSize(collisionSize.width, collisionSize.height)

    sprite.color = '0x77A6DD';


	return sprite

})