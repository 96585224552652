import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class Play extends Phaser.Scene
{

    constructor()
    {
        super('play')
    }

    preload() {



    }

    create()
    {

    	let context = this

    	// add background image
    	let play = this.add.sprite(600, 350, 'click-to-play-coin').setInteractive();

    	play.on('pointerdown', function (pointer) {

    		context.scale.startFullscreen()
    		context.scene.start("title");

    	})

    }

}