import Phaser from 'phaser'
import Preloader from './scenes/Preloader'
import SelectNumberOfPlayers from './scenes/SelectNumberOfPlayers'
import SelectCharacters from './scenes/SelectCharacters'
import Resume from './scenes/Resume'
import Title from './scenes/Title'
import Game from './scenes/Game'
import HallOfFame from './scenes/HallOfFame'
import EnterName from './scenes/EnterName'
import Play from './scenes/Play'

//import Select from './scenes/Select'
//import Complete from './scenes/Complete'

const config = {
	type: Phaser.AUTO,
	parent: 'game',
	transparent: true,
    scale: {
    	parent: 'game',
    	fullscreenTarget: 'game',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        zoom: 1,
		width: 1200,
		height: 700,
    },
    dom: {
        createContainer: true
    },
	physics: {
		default: 'arcade',
		arcade: {
            gravity: {y: 500},
            debug: false
        }
	},
	scene: [Preloader, SelectNumberOfPlayers, SelectCharacters, Title, Game, Resume, EnterName, HallOfFame, Play]
	//scene: [Preloader, Game, Complete]
	//scene: [Preloader, Select, Complete]
}

export default new Phaser.Game(config)
