const turn = (character) => {
	character.direction = (character.direction == 'left') ? 'right' : 'left'
}

const directionListener = (character, speed) => {

	if (character.stunned === true) { return; }

	switch(character.direction) {
		
		case "left":
			character.scaleX = -1;
			character.body.offset.x = 85
			character.body.width = 65
			character.setVelocityX(-speed)
			break;
		
		case "right":
			character.scaleX = 1;
			character.body.offset.x = 20
			character.setVelocityX(speed)
			break;

	}

}


const isTileUnderEnemy = (enemy, context) => {
    let xOffset = (enemy.direction == 'right') ? (enemy.width / 2) + 3 : -(enemy.width / 2) - 3
    let x = enemy.x + xOffset;
    let y = enemy.y + (enemy.height / 2) + 20
    //this.add.circle(x, y, 20, 0x6666ff);
    return (context.groundLayer.getTileAtWorldXY(x, y)) ? true : false;
}

const isTileInFrontOfEnemy = (enemy, context) => {
    let xOffset = (enemy.direction == 'right') ? (enemy.width / 2) + 3 : -(enemy.width / 2) - 3
    let x = enemy.x + xOffset;
    let y = enemy.y + (enemy.height / 2) - 15;
    return (context.groundLayer.getTileAtWorldXY(x, y)) ? true : false;
}

export { turn, directionListener, isTileUnderEnemy, isTileInFrontOfEnemy }