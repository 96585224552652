import Phaser from 'phaser'
import { randomIntFromInterval } from '../utils/math'
import { directionListener } from '../utils/characterActions'

export default class StandardEnemy extends Phaser.Physics.Arcade.Sprite
{
	constructor(scene, x, y, texture, frame)
	{

		super(scene, x, y, texture, frame) // set position

		let nameOptions = ['narwad', 'greenhorn', 'thorn', 'scratch']

		this.name = nameOptions[Math.floor(Math.random() * nameOptions.length)];

		this.direction = "left" // default walking direction
		this.speed = 100 // set speed
		this.health = 100


        this.anims.create({
            key: 'walk',
            frames: [{key: this.name, frame: 'walk.png'}, {key: this.name, frame: 'idle.png'}],
            frameRate: 7,
            repeat: -1
        });

		this.anims.play('walk') // apply animated sprite

	}

	hurt = (weapon, context) => {

		if (this.stunned == true) { return; }

		weapon.damage = (weapon.damage) ? weapon.damage : 40;

		this.health += -weapon.damage

		console.log('health', this.health)

		if (this.health <= 0) {

			this.killed(context)

		} else {

			this.stunned = true;

	        let dx = this.x - weapon.x
	        let dy = this.y - weapon.y
	        let dir = new Phaser.Math.Vector2(dx, dy).normalize()

	        console.log(dir)
	        
	        dir = dir.scale(weapon.damage)
	        console.log(dir)

	        this.setTint(0x777777)
	        this.setVelocity(dx, -(weapon.damage * 10))

	        var timer = this.scene.time.addEvent({
	            delay: 1000,
	            callback: () => {
	                this.stunned = false;
	                this.setTint(0xFFFFFF)
	            },
	            callbackScope: this,
	            loop: false
	        });

		}

	}

	killed = (context) => {

        context.sounds.hit.play();

        this.alpha = 0.4
        this.body.checkCollision.none = true;

        this.setVelocityY(context.randomIntFromInterval(-1000, -200))
        this.setVelocityX(70)


        var timer = this.scene.time.addEvent({
            delay: 1,                // ms
            callback: () => {
                this.angle += 7;
            },
            //args: [],
            callbackScope: context,
            loop: true
        });

	}

	preUpdate = (t, dt) => { 

		super.preUpdate(t, dt)

		directionListener(this, this.speed)
	}

}