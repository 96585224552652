const drawColliders = (tileLayer, context) => {

    const debugGraphics = context.add.graphics().setAlpha(0.75);
    tileLayer.renderDebug(debugGraphics, {
      tileColor: context, // Color of non-colliding tiles
      collidingTileColor: new Phaser.Display.Color(243, 134, 48, 255), // Color of colliding tiles
      faceColor: new Phaser.Display.Color(40, 39, 37, 255) // Color of colliding face edges
    });

}

export { drawColliders }