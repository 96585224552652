import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class Resume extends Phaser.Scene
{

    constructor()
    {
        super('resume')
    }

    preload() {
    }

    init(data) {
        this.musicPlaying = data.musicPlaying;
    }

    create()
    {

        let context = this;

        this.add.image(600, 350, 'resume-previous-game');

        var leftKey = this.input.keyboard.addKey('A');
        var rightKey = this.input.keyboard.addKey('D');
        var submitKey = this.input.keyboard.addKey(13); // return

        var leftKeyArrow = this.input.keyboard.addKey(37);
        var rightKeyArrow = this.input.keyboard.addKey(39);

        let selection = this.add.image(400, 320, 'select-count-indicator');

        this.add.image(400, 340, 'resume-yes');
        this.add.image(800, 340, 'resume-no');

        selection.value = 1

        leftKey.on('down', (event) => context.goLeft(selection))
        leftKeyArrow.on('down', (event) => context.goLeft(selection))

        rightKey.on('down', (event) => context.goRight(selection))
        rightKeyArrow.on('down', (event) => context.goRight(selection))

        submitKey.on('down', function(event) {

        	// load saved game
        	if (selection.value == 1) { 

        		let savePoint = getCookie('savePoint')

        		context.musicPlaying.stop()

        		const savePointObj = JSON.parse(savePoint);

        		console.log(savePointObj)


	            context.scene.start("game", 
	                { 
	                	"players": savePointObj.players,
	                	"level": savePointObj.level,
	                	"worldX": savePointObj.worldX,
	                	"worldY": savePointObj.worldY
	                }
	            );
	            

    		// start new game
        	} else {
        		context.scene.start(
        			"select-number-of-players",
	                { 
                    "playerCount": selection.value,
                    "musicPlaying": context.musicPlaying
                	}
            	)
        	}

        });

    }

    goLeft(selection) {
        selection.value--;
        let updatedValues = this.getNewSelection(selection.value)
        selection.x = updatedValues.x
        selection.value = updatedValues.value
    }

    goRight(selection) {
        selection.value++;
        let updatedValues = this.getNewSelection(selection.value)
        selection.x = updatedValues.x
        selection.value = updatedValues.value
    }

    getNewSelection(value) {

        if (value == 0) { value = 2 }
        if (value == 3) { value = 1 }

        if (value == 1) {
            return { x: 400, value: 1 }
        } else if (value == 2) {
            return { x: 800, value: 2 }
        }
    }

}