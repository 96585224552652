const getPlayersCenter = (players) => {
    let xSum = 0;
    let ySum = 0;
    let playerCount = 0;
    players.forEach((player, index, array) => { 
        if (!player.body.enable) { return; }
        xSum += player.x
        ySum += player.y
        playerCount++;
    })
    return {
        x: xSum / playerCount,
        y: ySum / playerCount
    }
}

const configureCamera = (context) => {

    // set bounds so the camera won't go outside the game world
    context.cameras.main.setBounds(0, 0, context.map.widthInPixels, context.map.heightInPixels);

    // make the camera follow the player
    context.cameras.main.startFollow(context.cameraCenter, false, 0.1);

    // set background color, so the sky is not black
    context.cameras.main.setBackgroundColor('#ccccff');

    context.leftBoundary = context.add.rectangle(200, 700, 50, 5000, 0x6666ff);
    context.physics.world.enable(context.leftBoundary, Phaser.Physics.Arcade.DYNAMIC_BODY)
    //this.leftBoundary.body.setGravity(0, 0);
    context.leftBoundary.body.moves = false;
    context.leftBoundary.body.pushable = false;
    context.leftBoundary.visible = false;

    context.rightBoundary = context.add.rectangle(200, 700, 50, 5000, 0x6666ff);
    context.physics.world.enable(context.rightBoundary, Phaser.Physics.Arcade.DYNAMIC_BODY)
    context.rightBoundary.body.setGravity(0, 0);
    context.rightBoundary.body.moves = false;
    context.rightBoundary.body.pushable = false;
    context.rightBoundary.visible = false;

    context.players.forEach((player, index, array) => {
        context.physics.add.collider(player, context.leftBoundary)
        context.physics.add.collider(player, context.rightBoundary)
    })

}

export { getPlayersCenter, configureCamera }