import Phaser from 'phaser'

import { setCookie, getCookie, eraseCookie } from '../utils/cookies'

export default class Title extends Phaser.Scene
{

    constructor()
    {
        super('hall-of-fame')
    }

    preload() {

        this.sounds = {
            music: {
                sunset: this.sound.add("sunset", { loop: true }),
            }
        }


    	this.load.html('nameform', 'assets/html/form.html');


    }

    create()
    {

    	let context = this

    	// add background image
    	this.add.image(600, 350, 'background-no-text');


        fetch('https://crate.onrender.com/rank/themiggies')
          .then(response => response.json())
          .then(data => {

            let names = []

            data.forEach((entry) => {
                names.push(entry.name)
            })

            var el = document.createElement('marquee');
            el.setAttribute("direction", "up")
            el.style = 'width: 600px;  height: 700px; font-size: 50px; text-align: center;';
            el.innerText = names.join("\n");

            context.add.dom(600, 350, el);

          })



/*
    	var element = this.add.dom(590, 450).createFromCache('nameform');

        element.addListener('onload');

        element.on('click', function (event) {
            console.log('loaded')
        })

        console.log(element)
        //let value = element.querySelector("#test").value;
        let value = element.getChildByName('test')
        console.log(value)
        */


    }

}